import React from 'react';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { PollApiProvider } from './forms/context/ApiContext';
import { getConfig, getEnv } from './config';
import Router from './Router';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: getEnv(),
});

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Auth0Provider
    domain={getConfig('REACT_APP_AUTH0_DOMAIN')}
    clientId={getConfig('REACT_APP_AUTH0_CLIENT_ID')}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={false}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: getConfig('REACT_APP_AUTH0_AUDIENCE'),
    }}
  >
    <PollApiProvider>
      <Router />
    </PollApiProvider>
  </Auth0Provider>
);
