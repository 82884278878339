import React from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import TopicView from './forms/components/views/TopicView';
import QuestionnaireView from './forms/components/views/QuestionnaireView';
import App from './App';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <HomePage /> },
          {
            path: 'questionnaire/:questionnaireId',
            element: <Outlet />,
            children: [
              { index: true, element: <QuestionnaireView /> },
              {
                path: 'category/:categoryId',
                element: <Outlet />,
                children: [
                  { index: true, element: <QuestionnaireView /> },
                  {
                    path: 'topic/:topicId',
                    element: <TopicView />,
                  },
                ],
              },
            ],
          },
          /* the rest of the routes */
        ],
      },
    ],
  },
];
const router = createBrowserRouter(routes);
export default function Router() {
  return <RouterProvider router={router} />;
}
