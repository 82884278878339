import axios from 'axios';

const fetchJsonWithAuth = (
  getAccessTokenSilently,
  url,
  method,
  reqData = null
) => {
  // Default options are marked with *
  return fetchWithAuth(getAccessTokenSilently, url, method, reqData).then(
    (response) => {
      if (!response)
        throw new Error("Request to '" + url + "' failed: no response");
      return response.data;
    }
  );
};

const fetchBlobWithAuth = (getAccessTokenSilently, url) => {
  // Default options are marked with *
  return fetchWithAuth(getAccessTokenSilently, url, 'GET', null, 'blob').then(
    (response) => {
      if (!response)
        throw new Error("Request to '" + url + "' failed: no response");
      //console.log("got response: ", response)
      return response.data;
    }
  );
};

function fetchWithAuth(
  getAccessTokenSilently,
  url,
  method,
  reqData = null,
  responseType = null
) {
  return getAccessTokenSilently().then((token) => {
    return axios(url, {
      method: method,
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      responseType: responseType,
      data: reqData ? JSON.stringify(reqData) : null, // body data type must match "Content-Type" header
    });
  });
}

const sendMultipartWithAuth = (
  getAccessTokenSilently,
  url,
  method,
  file,
  reqData = {}
) => {
  // Default options are marked with *
  return getAccessTokenSilently()
    .then((token) => {
      var formData = new FormData();
      formData.append('file', file, file.name);
      for (const [key, value] in reqData) {
        formData.append(key, value);
      }

      return axios({
        url: url,
        method: method,
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        data: formData, // body data type must match "Content-Type" header
      });
    })
    .then((response) => {
      if (!response)
        throw new Error("Request to '" + url + "' failed: no response");
      return response.data;
    });
};

export { fetchJsonWithAuth, sendMultipartWithAuth, fetchBlobWithAuth };
