import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';

import { defaultStyles } from '../../layout/styleProvider';
import { PollContext } from '../../context/PollContext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { AnswerContext } from '../../context/AnswerContext';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import CategoriesList from './CategoriesList';
import { NavigateNext } from '@mui/icons-material';
import { Stack } from '@mui/material';
import QuestionnaireViewSkeleton from './QuestionnaireViewSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
    '&:hover': { textDecoration: 'underline' },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const QuestionnaireView = () => {
  const localClasses = useStyles();
  const { selectedQuestionnaire, selectedCategory, questionCountTotal } =
    useContext(PollContext);
  const { answerCountTotal } = useContext(AnswerContext);

  const classes = defaultStyles();
  const fixedHeightHeader = clsx(classes.paper, classes.fixedHeightMedium);

  if (!selectedQuestionnaire) return <QuestionnaireViewSkeleton />;

  let answerCount = answerCountTotal();
  let questionCount = questionCountTotal();
  let answerProgress = (answerCount / questionCount) * 100.0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.breadcrumbContainer} elevation={0}>
          {selectedCategory ? (
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext />}>
              <Link
                className={localClasses.link}
                to={`/questionnaire/${selectedQuestionnaire.id}`}
              >
                <NavigateNext sx={{ pr: 1, opacity: 0.6 }} />
                {selectedQuestionnaire.title}
              </Link>
              <Typography color="textPrimary">
                {selectedCategory.title}
              </Typography>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <Stack direction={'row'}>
                <NavigateNext sx={{ pr: 1 }} />
                <Typography color="textPrimary">
                  {selectedQuestionnaire.title}
                </Typography>
              </Stack>
            </Breadcrumbs>
          )}
        </Paper>
      </Grid>
      {/* Title and Description */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightHeader}>
          <Typography variant="h1" gutterBottom>
            {selectedQuestionnaire.title}
          </Typography>
          <Typography>{selectedQuestionnaire.description}</Typography>
        </Paper>
      </Grid>
      {/* Status */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightHeader}>
          <Typography variant="h2" gutterBottom>
            Status
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            {answerCount + ' of ' + questionCount}
          </Typography>
          <LinearProgress variant="determinate" value={answerProgress} />
        </Paper>
      </Grid>
      {/* Categories */}
      <CategoriesList />
    </Grid>
  );
};

export default QuestionnaireView;
