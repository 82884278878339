import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { defaultStyles } from '../../layout/styleProvider';
import Button from '@mui/material/Button';
import { MessageContext } from '../../context/MessageContext';

const Messages = () => {
  const { errorMsg, confirmErrors } = useContext(MessageContext);

  const classes = defaultStyles();

  if (errorMsg) {
    return (
      <>
        <div className={classes.title}>
          <Typography
            data-testid="errorMsg"
            variant="subtitle2"
            color="error"
            noWrap
            className={classes.errorMsg}
          >
            {errorMsg}
          </Typography>
        </div>

        <Button
          color="primary"
          className={classes.button}
          onClick={confirmErrors}
          variant="text"
        >
          Close
        </Button>
      </>
    );
  } else {
    return <></>;
  }
};

export default Messages;
