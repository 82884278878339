import React, { useContext } from 'react';
import { QuestionTypes } from './Question';
import TextField from '@mui/material/TextField';
import { AnswerContext } from '../../context/AnswerContext';

const TextType = (props) => {
  const { handleAnswerChange } = useContext(AnswerContext);

  function createAnswerBody(value) {
    return {
      text: value,
    };
  }

  const answerText = props.answer ? props.answer.body.text : '';
  return (
    <>
      <form noValidate>
        <TextField
          inputProps={{ 'data-testid': 'answerfield' }}
          id="outlined-multiline-flexible"
          label="Your Answer"
          multiline
          fullWidth
          maxRows={1000}
          value={answerText}
          margin="normal"
          variant="outlined"
          disabled={props.disabled}
          onChange={(event) =>
            handleAnswerChange(
              QuestionTypes.TEXT,
              props.question.id,
              createAnswerBody(event.target.value)
            )
          }
        />
      </form>
    </>
  );
};

export default TextType;
