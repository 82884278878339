import React, { useContext } from 'react';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import TopicsList from './TopicsList';
import PollContext from '../../context/PollContext';
import AnswerContext from '../../context/AnswerContext';
import { useNavigate } from 'react-router-dom';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { defaultStyles } from '../../layout/styleProvider';
import CategoriesSkeleton from './CategoriesSkeleton';

const CategoriesList = () => {
  const navigate = useNavigate();
  const classes = defaultStyles();
  const {
    selectedQuestionnaire,
    categories,
    selectedCategory,
    questionCountByCategory,
    categoriesLoading,
  } = useContext(PollContext);
  const { answerCountByCategory } = useContext(AnswerContext);

  const toggleExpand = (selectedQuestionnaire, category) => {
    if (selectedCategory && category.id === selectedCategory.id) {
      navigate(`/questionnaire/${selectedQuestionnaire.id}`);
    } else {
      navigate(
        `/questionnaire/${selectedQuestionnaire.id}/category/${category.id}`
      );
    }
  };

  if (categoriesLoading) {
    return <CategoriesSkeleton />;
  }
  return (
    <>
      {categories &&
        categories.map((category) => {
          const expanded =
            selectedCategory && category.id === selectedCategory.id;
          let answerCount = answerCountByCategory(category.id);
          let questionCount = questionCountByCategory(category.id);
          let answerProgress = (answerCount / questionCount) * 100.0;

          return (
            <Grid item xs={12} md={6} lg={4} key={category.id}>
              <Card className={classes.card}>
                <CardContent
                  className={classes.fixedHeightSmall}
                  onClick={() => toggleExpand(selectedQuestionnaire, category)}
                >
                  <Typography color="textSecondary">
                    {answerCount} of {questionCount}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={answerProgress}
                  />
                  <Typography variant="h2" gutterBottom mt={2}>
                    {category.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {category.description}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={() =>
                      toggleExpand(selectedQuestionnaire, category)
                    }
                    aria-expanded={expanded}
                    aria-label="show topics"
                    size="large"
                  >
                    {<ExpandMoreIcon />}
                  </IconButton>
                </CardActions>
                <Collapse in={expanded} unmountOnExit>
                  <TopicsList expanded={expanded} />
                </Collapse>
              </Card>
            </Grid>
          );
        })}
    </>
  );
};

export default CategoriesList;
