import React, { useContext } from 'react';
import { QuestionTypes } from './Question';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AnswerContext } from '../../context/AnswerContext';

const YesNoType = (props) => {
  const { handleAnswerChange } = useContext(AnswerContext);

  function getAnswerBody(value) {
    return {
      value: value,
    };
  }

  let answerValue = props.answer ? props.answer.body.value : null;
  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup
        aria-label="position"
        name="position"
        value={answerValue}
        onChange={(event) =>
          handleAnswerChange(
            QuestionTypes.YES_NO,
            props.question.id,
            getAnswerBody(event.target.value)
          )
        }
        row
      >
        <FormControlLabel
          value="Yes"
          control={<Radio color="primary" />}
          label="Yes"
          labelPlacement="bottom"
          disabled={props.disabled}
        />
        <FormControlLabel
          value="No"
          control={<Radio color="primary" />}
          label="No"
          labelPlacement="bottom"
          disabled={props.disabled}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default YesNoType;
