import React, { useContext } from 'react';
import { QuestionTypes } from './Question';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AnswerContext } from '../../context/AnswerContext';
import { Checkbox, FormGroup } from '@mui/material';

const MultipleChoiceType = ({ answer, question, disabled }) => {
  const { handleAnswerChange } = useContext(AnswerContext);

  function getAnswerBody(value) {
    return {
      value: value,
    };
  }

  let answerValue = answer ? answer.body.value : [];
  let options = question.payload.options;

  const handleOnChange = (value) => {
    let newAnswer = answerValue.includes(value)
      ? answerValue.filter((item) => item !== value)
      : [...answerValue, value];
    handleAnswerChange(
      QuestionTypes.MULTIPLE_CHOICE,
      question.id,
      getAnswerBody(newAnswer)
    );
  };

  return (
    <FormControl variant="standard" component="fieldset">
      <FormGroup aria-label="position" name="position" value={answerValue}>
        {Object.keys(options).map((key) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={answerValue.includes(key)}
                  onChange={() => handleOnChange(key)}
                  disabled={disabled}
                />
              }
              key={question.id + '-option-' + key}
              value={key}
              label={options[key]}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default MultipleChoiceType;
