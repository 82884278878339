import React, { useContext } from 'react';

import QuestionnaireSelection from './QuestionnaireSelection';
import { Box } from '@mui/material';
import SaveButton from './SaveButton';
import { PollContext } from '../../context/PollContext';
import { colorScheme } from '../../layout/globals';

const Explorer = () => {
  const { selectedQuestionnaire, auditMode } = useContext(PollContext);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <QuestionnaireSelection />

      {selectedQuestionnaire && !auditMode && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            backgroundColor: colorScheme.blue20,
            width: '100%',
            borderColor: colorScheme.grey4,
            paddingY: 0.5,
          }}
        >
          <SaveButton />
        </Box>
      )}
    </Box>
  );
};

export default Explorer;
