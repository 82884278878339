import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  const error = useRouteError() as any;
  return (
    <Stack
      width={'100%'}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Typography variant="h2" fontWeight={700}>
        {error?.status ?? 'Oops! Something went wrong.'}
      </Typography>
      <p>
        {error?.statusText ??
          'We apologize for the inconvenience. Please try again later.'}
      </p>
    </Stack>
  );
};

export default ErrorPage;
