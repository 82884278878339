import { replaceAllNonAlphanumericCharacters } from './stringUtils';

export const normalizeFileName = (originalFile: File) => {
  const normalizedName = replaceAllNonAlphanumericCharacters(originalFile.name);

  return new File([originalFile], normalizedName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
