import React from 'react';
import clsx from 'clsx';
import { Grid, Paper, Skeleton } from '@mui/material';
import Title from '../dashboard/Title';
import { defaultStyles } from '../../layout/styleProvider';
import CategoriesSkeleton from './CategoriesSkeleton';

export const QuestionnaireDetailsSkeleton = () => {
  const classes = defaultStyles();
  const fixedHeightHeader = clsx(classes.paper, classes.fixedHeightMedium);
  return (
    <>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={24} />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightHeader}>
          <Title>
            <Skeleton variant="text" width={300} />
          </Title>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Paper>
      </Grid>
      {/* Status */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightHeader}>
          <Title>
            <Skeleton variant="text" width={100} />
          </Title>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Paper>
      </Grid>
    </>
  );
};

const QuestionnaireSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <QuestionnaireDetailsSkeleton />
      <CategoriesSkeleton />
    </Grid>
  );
};

export default QuestionnaireSkeleton;
