import { makeStyles } from '@mui/styles';
import { colorScheme, globalFontFamily } from './globals';

const drawerWidth = 300;

const questionStyles = makeStyles((theme) => ({
  questionGrid: {
    borderRightWidth: '1px',
    borderRightColor: theme.palette.divider,
    borderRightStyle: 'solid',
    paddingRight: 8,
  },
  answerStatus: {
    float: 'right',
  },
}));

const defaultStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: globalFontFamily,
    height: '100vh',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    height: 64,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: colorScheme.blue20,
    color: colorScheme.grey0,
    boxShadow: 'none',
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: colorScheme.blue20,
    overflowX: 'hidden',
    border: 'none',
    height: '100vh',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: colorScheme.blue20,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(4),
    height: 'calc(100vh - 64px)',
    backgroundColor: colorScheme.grey7,
    borderTopLeftRadius: 24,
    overflowY: 'scroll',
    overflowX: 'auto',
    width: '100%',
    maxWidth: 'unset',
    margin: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderWidth: '2px',
    borderColor: colorScheme.grey6,
    borderStyle: 'solid',
    boxShadow: 'none',
    borderRadius: 12,
  },
  breadcrumbContainer: {},
  card: {
    backgroundColor: colorScheme.grey6,
    boxShadow: 'none',
    borderRadius: 12,
    cursor: 'pointer',
  },
  fixedHeight: {
    height: 160,
  },
  fixedHeightMedium: {
    height: 120,
  },
  fixedHeightSmall: {
    height: 100,
  },
  headerLogo: {
    height: '2em',
    width: 'auto',
    marginRight: 10,
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  hiddenInput: {
    display: 'none',
  },
  fileList: {},
  errorMsg: {
    textAlign: 'right',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  footerLinks: {
    flexGrow: 1,
  },
  footerText: {
    fontSize: '14px !important',
    '& > span': {
      fontSize: '14px !important',
    },
  },
  footerLink: {
    width: 'auto',
    flexGrow: 0,
    display: 'inline-block',
    fontSize: '14px !important',
    '& > a': {
      color: colorScheme.grey1,
      textDecoration: 'none',
      '&:visited': {
        color: colorScheme.grey1,
      },
      '&:hover': {
        color: colorScheme.blue,
        textDecoration: 'underline',
      },
    },
  },

  mainNavigation: {
    color: colorScheme.grey0,
    paddingBottom: 76,
  },
  mainNavigationIcon: {
    fill: colorScheme.grey0,
    paddingRight: 12,
  },
  mainNavigationItem: {
    padding: 12,
    marginLeft: 12,
    marginRight: 12,
    width: 'auto',
    minWidth: 'calc(100% - 24px)',
    textWrap: 'wrap',
    marginBottom: 8,
    color: colorScheme.grey0,
    borderRadius: 12,
    '&:hover': {
      background: `${colorScheme.grey7}`,
    },
    transition: 'all 0.2s',
  },
  mainNavigationItemActive: {
    background: `${colorScheme.grey7} !important`,
    borderRadius: 12,
    fontWeight: 700,
    marginRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  mainNavigationItemInactive: {
    padding: 12,
    marginLeft: 12,
    marginRight: 12,
    color: colorScheme.grey0,
    borderRadius: 12,
    '&:hover': {
      background: `${colorScheme.grey5} !important`,
    },
  },
  mainNavigationSaveButton: {
    color: colorScheme.grey0,
    padding: '2px 12px !important',
    minHeight: 'unset',
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 12,
    textTransform: 'none',
    textAlign: 'left',
    width: 'calc(100% - 24px)',
    minWidth: 100,
    boxShadow: '0px 2px 10px rgba(0, 0, 255, 0.08)',

    background: colorScheme.grey7,
    '&:disabled': {
      background: colorScheme.grey7,
      opacity: 0.6,
      boxShadow: 'none',
      height: 54,
    },
    '&:hover': {
      background: colorScheme.grey7,
      boxShadow: '0px 2px 15px rgba(0, 0, 255, 0.12)',
    },
  },
  mainNavigationSaveButtonText: {
    color: `${colorScheme.blue} !important`,
    '& > p': {
      color: `${colorScheme.blue} !important`,
    },
    '& > span': {
      color: `${colorScheme.blue} !important`,
      fontWeight: 700,
    },
  },
  mainNavigationSaveButtonSaveIcon: {
    fill: colorScheme.blue,
    paddingRight: 12,
    '&:hover': {
      color: colorScheme.blue,
    },
  },
  mainNavigationSaveButtonCircularProgress: {
    fill: colorScheme.blue,
    '&:hover': {
      color: colorScheme.blue,
    },
  },
}));

export { defaultStyles, questionStyles };
