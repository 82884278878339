import { Stack, Typography } from '@mui/material';
import React from 'react';

const HomePage: React.FC = () => {
  return (
    <Stack width={'100%'} height={'100%'}>
      <Typography variant="h3" fontWeight={700}>
        Welcome to the VDR
      </Typography>
      <p>Please select a Questionnaire on the left side.</p>
    </Stack>
  );
};

export default HomePage;
