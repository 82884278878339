import React, { useState } from 'react';
import { MessageContext } from '../../context/MessageContext';

const MessageManagement = (props) => {
  // error handling
  const [errorMsg, setErrorMsg] = useState();
  const handleError = async (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    setErrorMsg(error.toString());
  };

  const confirmErrors = async () => {
    setErrorMsg('');
  };

  return (
    <MessageContext.Provider value={{ errorMsg, handleError, confirmErrors }}>
      {props.children}
    </MessageContext.Provider>
  );
};

export default MessageManagement;
