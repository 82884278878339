export const colorScheme = {
  grey0: '#182631',
  grey1: '#263540',
  grey2: '#677986',
  grey3: '#81919C',
  grey4: '#C5CED5',
  grey5: '#C5CED5',
  grey6: '#F0F3F7',
  grey7: '#FFFFFF',
  blue: '#3B6AE3',
  blue20: '#EBF0FC',
  blueFaded: 'rgba(59, 106, 227, .7)',
  green: '#A9DC77',
  lightGreen: '#DBEC72',
  red: '#DB4C5D',
  lightRed: '#FF8469',
  lighterRed: '#FFDBAB',
};

export const globalFontFamily = 'Inter';
