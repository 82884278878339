import { colorScheme, globalFontFamily } from './globals';
import { createTheme } from '@mui/material';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colorScheme.blue,
    },
    secondary: {
      main: colorScheme.grey6,
      A700: colorScheme.grey5,
    },
    success: { main: colorScheme.green, contrastText: colorScheme.grey7 },
  },
  typography: {
    fontFamily: globalFontFamily,
    h1: { letterSpacing: '-0.05em', fontSize: 32, fontWeight: 700 },
    h2: { letterSpacing: '-0.03em', fontSize: 24, fontWeight: 700 },
    h3: { letterSpacing: '-0.02em', fontSize: 16, fontWeight: 700 },
    h5: { letterSpacing: '-0.02em', fontWeight: 500 },
    h6: { letterSpacing: '-0.02em', fontWeight: 500 },
    body1: { letterSpacing: '-0.02em', fontWeight: 400, lineHeight: '22px' },
    allVariants: { letterSpacing: '-0.02em' },
  },
  components: {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontFamily: globalFontFamily,
        },
      },
    },
    MuiSkeleton: { styleOverrides: { rectangular: { borderRadius: 12 } } },
    MuiButton: {
      styleOverrides: {
        contained: {},
        outlined: {
          borderWidth: 2,
          color: colorScheme.grey0,
          borderColor: colorScheme.grey0,
          ':hover': {
            borderWidth: 2,
            color: colorScheme.grey0,
            borderColor: colorScheme.grey0,
          },
        },
        sizeLarge: { minHeight: 56, padding: '16px 24px', fontSize: 16 },
        root: {
          borderRadius: 100,
          fontWeight: '700',
          minHeight: 44,
          boxShadow: 'none',
          padding: '12px 20px',
          fontSize: 14,
          textTransform: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
