import React, { useContext } from 'react';
import Link from '@mui/material/Link';

import { useAuth0 } from '@auth0/auth0-react';
import { fetchBlobWithAuth } from '../../../utils/requestUtils';
import { MessageContext } from '../../context/MessageContext';
import { ConfigContext } from '../../context/ConfigContext';

const AnswerFileLink = (props) => {
  const { handleError } = useContext(MessageContext);
  const { getAccessTokenSilently } = useAuth0();
  const { apiUrlPrefix } = useContext(ConfigContext);

  function downloadFile() {
    let filename = props.file.name;
    let answerId = props.answer.id;
    let request_url = `${apiUrlPrefix}/forms/api/answers/${answerId}/files/${filename}`;

    fetchBlobWithAuth(getAccessTokenSilently, request_url)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  return (
    <Link
      component="button"
      variant="body2"
      onClick={() => downloadFile()}
      underline="hover"
    >
      {props.linkContent}
    </Link>
  );
};

export default AnswerFileLink;
