import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { defaultStyles } from '../../layout/styleProvider';
import { PollContext } from '../../context/PollContext';
import { AnswerContext } from '../../context/AnswerContext';
import { CircularProgress, Stack } from '@mui/material';
import { Questionnaire, Topic } from '../../../api-client/poll-api';
import { useNavigate } from 'react-router-dom';
import { Check, NavigateBefore, NavigateNext } from '@mui/icons-material';

type TSubmitTarget = {
  questionnaire?: Questionnaire;
  topic?: Topic;
};

const TopicPagination = () => {
  const { prevTopic, nextTopic, selectedQuestionnaire, selectedCategory }: any =
    useContext(PollContext);
  const navigate = useNavigate();
  const { handleSaveAnswers, answerPersistenceState } =
    useContext(AnswerContext);
  const [submittedTarget, setSubmittedTarget] = useState<
    undefined | TSubmitTarget
  >(undefined);

  const classes = defaultStyles();

  const submitAnswers = (target: TSubmitTarget) => {
    window.onbeforeunload = () => true;
    handleSaveAnswers();
    setSubmittedTarget(target);
  };

  useEffect(() => {
    if (submittedTarget && answerPersistenceState === 'saved') {
      window.onbeforeunload = () => undefined;
      if (submittedTarget.questionnaire && submittedTarget.topic) {
        navigate(
          `/questionnaire/${submittedTarget.questionnaire.id}/category/${selectedCategory.id}/topic/${submittedTarget.topic.id}`
        );
      } else if (submittedTarget.questionnaire) {
        navigate(`/questionnaire/${submittedTarget.questionnaire.id}`);
      }
    }
  }, [submittedTarget, answerPersistenceState]);

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      {prevTopic ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className={classes.button}
          disabled={answerPersistenceState === 'saving'}
          startIcon={
            answerPersistenceState === 'saving' ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              <NavigateBefore />
            )
          }
          onClick={() =>
            submitAnswers({
              questionnaire: selectedQuestionnaire,
              topic: prevTopic,
            })
          }
        >
          Previous topic
        </Button>
      ) : (
        <div />
      )}
      {nextTopic ? (
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          disabled={answerPersistenceState === 'saving'}
          endIcon={
            answerPersistenceState === 'saving' ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              <NavigateNext />
            )
          }
          onClick={() =>
            submitAnswers({
              questionnaire: selectedQuestionnaire,
              topic: nextTopic,
            })
          }
        >
          Next topic
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          disabled={answerPersistenceState === 'saving'}
          startIcon={
            answerPersistenceState === 'saving' ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              <Check />
            )
          }
          onClick={() =>
            submitAnswers({ questionnaire: selectedQuestionnaire })
          }
        >
          Done
        </Button>
      )}
    </Stack>
  );
};

export default TopicPagination;
