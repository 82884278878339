import React from 'react';
import { Grid, List, ListItem, ListItemText, Box } from '@mui/material';
import { defaultStyles } from '../../layout/styleProvider';
import packageInfo from '../../../../package.json';

const Footer = () => {
  const classes = defaultStyles();

  return (
    <Grid container spacing={0}>
      <Grid
        container
        item
        xs={12}
        md={8}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <List className={classes.footerLinks}>
          <ListItem component="li" className={classes.footerLink}>
            <a
              href="https://www.techminers.com/imprint"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText primary="Imprint" className={classes.footerText} />
            </a>
          </ListItem>

          <ListItem component="li" className={classes.footerLink}>
            <a
              href="https://www.techminers.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText primary="Privacy" className={classes.footerText} />
            </a>
          </ListItem>
          <ListItem component="li" className={classes.footerLink}>
            <a
              href="https://www.techminers.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText
                primary={`© ${new Date().getFullYear()} - TechMiners`}
                className={classes.footerText}
              />
            </a>
          </ListItem>
        </List>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={4}
        justifyContent="flex-end"
        alignItems={'center'}
      >
        <ListItem component="li" className={classes.footerLink}>
          <Box
            sx={{
              p: '12px',
              opacity: 0.3,
              fontSize: '14px',
            }}
          >
            VDR v{packageInfo.version}
          </Box>
        </ListItem>
      </Grid>
    </Grid>
  );
};

export default Footer;
