import React, { useContext } from 'react';
import Save from '@mui/icons-material/Save';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { AnswerContext } from '../../context/AnswerContext';
import CircularProgress from '@mui/material/CircularProgress';
import { defaultStyles } from '../../layout/styleProvider';
import { Button } from '@mui/material';

const SaveButton = () => {
  const { answerPersistenceState, handleSaveAnswers, answerCountUnsaved } =
    useContext(AnswerContext);
  const classes = defaultStyles();

  function renderSaveButton() {
    if (answerPersistenceState === 'unsaved') {
      return (
        <Button
          onClick={() => handleSaveAnswers()}
          className={classes.mainNavigationSaveButton}
        >
          <Save className={classes.mainNavigationSaveButtonSaveIcon} />

          <ListItemText
            primary="Save Changes"
            secondary={'Changes: ' + answerCountUnsaved()}
            className={classes.mainNavigationSaveButtonText}
          />
        </Button>
      );
    } else if (answerPersistenceState === 'saving') {
      return (
        <Button className={classes.mainNavigationSaveButton} disabled>
          <ListItemIcon>
            <CircularProgress
              size={20}
              className={classes.mainNavigationSaveButtonCircularProgress}
            />
          </ListItemIcon>

          <ListItemText
            primary="Saving..."
            className={classes.mainNavigationSaveButtonText}
          />
        </Button>
      );
    } else if (answerPersistenceState === 'saved') {
      return (
        <Button disabled className={classes.mainNavigationSaveButton}>
          <Save className={classes.mainNavigationSaveButtonSaveIcon} />
          <ListItemText
            primary="No Changes"
            className={classes.mainNavigationSaveButtonText}
          />
        </Button>
      );
    } else {
      return <>Error: {answerPersistenceState}</>;
    }
  }

  return (
    <List sx={{ '& .MuiButtonBase-root': { padding: '8px 16px' } }}>
      {renderSaveButton()}
    </List>
  );
};

export default SaveButton;
