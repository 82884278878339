import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const CategoriesSkeleton = () => {
  return (
    <>
      {Array(4)
        .fill('')
        .map((item, idx) => (
          <Grid item xs={12} md={6} lg={4} key={idx}>
            <Skeleton variant="rectangular" height={196}></Skeleton>
          </Grid>
        ))}
    </>
  );
};

export default CategoriesSkeleton;
