import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import AnswerContext from '../../context/AnswerContext';
import PollContext from '../../context/PollContext';
import TopicIcon from '@mui/icons-material/LabelImportant';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, CircularProgress } from '@mui/material';

const TopicsList = ({ expanded }) => {
  const {
    questionCountByTopic,
    topics,
    selectedQuestionnaire,
    selectedCategory,
    topicsLoading,
    categoriesLoading,
  } = useContext(PollContext);
  const { answerCountByTopic } = useContext(AnswerContext);
  if (topicsLoading || categoriesLoading || topics.length === 0) {
    return (
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <List component="nav">
      {expanded &&
        topics &&
        topics.map((topic) => {
          const topicAnswerCount = answerCountByTopic(
            selectedCategory.id,
            topic.id
          );
          const topicQuestionCount = questionCountByTopic(
            selectedCategory.id,
            topic.id
          );
          const topicAnswerProgress =
            (topicAnswerCount / topicQuestionCount) * 100.0;

          return (
            <Link
              key={topic.id}
              to={`/questionnaire/${selectedQuestionnaire.id}/category/${selectedCategory.id}/topic/${topic.id}`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: 'inherit',
                  textTransform: 'none',
                  width: '100%',
                  borderRadius: 0,
                  padding: 0,
                }}
              >
                <ListItem>
                  <ListItemIcon>
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={topic.title}
                    primaryTypographyProps={{
                      fontSize: 16,
                      pb: 1,
                    }}
                    secondary={
                      <LinearProgress
                        variant="determinate"
                        value={topicAnswerProgress}
                      />
                    }
                  />
                </ListItem>
              </Button>
            </Link>
          );
        })}
    </List>
  );
};

export default TopicsList;
