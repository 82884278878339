import React, { useContext } from 'react';
import ListItem from '@mui/material/ListItem';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import List from '@mui/material/List';
import { defaultStyles } from '../../layout/styleProvider';

import { PollContext } from '../../context/PollContext';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const QuestionnaireSelection = () => {
  const { questionnaires, selectedQuestionnaire } = useContext(PollContext);
  const classes = defaultStyles();

  return (
    <List className={classes.mainNavigation} sx={{ border: 'none' }}>
      {questionnaires &&
        questionnaires.map((quest) => {
          const isSelected =
            selectedQuestionnaire && quest.id === selectedQuestionnaire.id;

          return (
            <Link
              to={`/questionnaire/${quest.id}`}
              key={quest.id}
              data-testid={quest.id}
            >
              <Typography>
                <ListItem
                  component="li"
                  className={`${classes.mainNavigationItem} ${
                    isSelected && classes.mainNavigationItemActive
                  }`}
                  sx={{
                    '& .MuiButtonBase-root': { padding: '8px 16px' },
                  }}
                >
                  <QuestionAnswerIcon className={classes.mainNavigationIcon} />
                  {quest.title}
                </ListItem>
              </Typography>
            </Link>
          );
        })}
    </List>
  );
};

export default QuestionnaireSelection;
