import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { QuestionnaireDetailsSkeleton } from './QuestionnaireViewSkeleton';

export const QuestionsSkeleton = () => {
  return (
    <>
      {Array(4)
        .fill('')
        .map((item, idx) => (
          <Grid item xs={12} key={idx}>
            <Skeleton variant="rectangular" height={196}></Skeleton>
          </Grid>
        ))}
    </>
  );
};
const TopicViewSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <QuestionnaireDetailsSkeleton />
      <QuestionsSkeleton />
    </Grid>
  );
};

export default TopicViewSkeleton;
