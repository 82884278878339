import React from 'react';

export const PollContext = React.createContext({
  questionnaires: [],
  questionnairesLoading: false,
  selectedQuestionnaire: undefined,
  qStats: undefined,
  categories: [],
  categoriesLoading: false,
  selectedCategory: undefined,
  topics: [],
  topicsLoading: false,
  selectedTopic: undefined,
  prevTopic: undefined,
  nextTopic: undefined,
  questions: [],
  auditMode: false,
  auditQResponseId: undefined,
  startAudit: () => {},
  activateAuditMode: () => {},
  questionCountTotal: () => {},
  questionCountByCategory: () => {},
  questionCountByTopic: () => {},
});

export default PollContext;
