import React, { useContext } from 'react';
import { QuestionTypes } from './Question';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AnswerContext } from '../../context/AnswerContext';
import styled from 'styled-components';

const VerticalRadioGroup = styled(RadioGroup)`
  flex-direction: column;
`;
const VerticalFormControlLabel = styled(FormControlLabel)`
  flex-direction: row;
`;

const RadioType = ({ answer, question, vertical, disabled }) => {
  const { handleAnswerChange } = useContext(AnswerContext);

  function getAnswerBody(value) {
    return {
      value: value,
    };
  }

  let DirectedRadioGroup = vertical ? VerticalRadioGroup : RadioGroup;
  let DirectedFormControlLabel = vertical
    ? VerticalFormControlLabel
    : FormControlLabel;

  let answerValue = answer ? answer.body.value : '';
  let options = question.payload.options;
  return (
    <FormControl variant="standard" component="fieldset">
      <DirectedRadioGroup
        aria-label="position"
        name="position"
        value={answerValue}
        onChange={(event) =>
          handleAnswerChange(
            QuestionTypes.RADIO,
            question.id,
            getAnswerBody(event.target.value)
          )
        }
        row
      >
        {Object.keys(options).map((key) => {
          return (
            <DirectedFormControlLabel
              key={question.id + '-option-' + key}
              value={key}
              disabled={disabled}
              control={<Radio color="primary" />}
              label={options[key]}
              labelPlacement="bottom"
            />
          );
        })}
      </DirectedRadioGroup>
    </FormControl>
  );
};

export default RadioType;
