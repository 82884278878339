import React, { useContext, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Divider from '@mui/material/Divider';
import { useAuth0 } from '@auth0/auth0-react';
import AnswerFileLink from './AnswerFileLink';
import { QuestionTypes } from './Question';
import { defaultStyles } from '../../layout/styleProvider';
import { AnswerContext } from '../../context/AnswerContext';
import { ConfigContext } from '../../context/ConfigContext';
import TextField from '@mui/material/TextField';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import XHR from '@uppy/xhr-upload';
import PollContext from '../../context/PollContext';
import styled from '@emotion/styled';
import { replaceAllNonAlphanumericCharacters } from '../../../utils/stringUtils';
import { normalizeFileName } from '../../../utils/fileUtils';
import { v4 as uuidv4 } from 'uuid';

const onBeforeUpload = (files) => {
  const updatedFiles = {};
  Object.entries(files).forEach(([key, file]) => {
    const cleanedFileName = replaceAllNonAlphanumericCharacters(file.name);
    const uniqueName = `${uuidv4().slice(0, 4)}-${cleanedFileName}`;
    updatedFiles[key] = {
      ...file,
      name: uniqueName,
      data: normalizeFileName(file.data),
      meta: {
        ...file.meta,
        name: uniqueName,
      },
    };
  });
  return updatedFiles;
};

const createUppy = (questionId) => {
  // Adding to global `meta` will add it to every file.
  // Every Uppy instance needs a unique ID.
  return new Uppy({
    id: questionId,
    onBeforeUpload,
    autoProceed: true,
  });
};

const FileType = (props) => {
  const { selectedQuestionnaire } = useContext(PollContext);
  const { handleAnswerChange, loadQResponse, createQResponse, qResponse } =
    useContext(AnswerContext);
  const { apiUrlPrefix } = useContext(ConfigContext);
  const { getAccessTokenSilently } = useAuth0();
  const [uppy] = useState(() => createUppy(props.question.id));
  const [answerId, setAnswerId] = useState(props.answer?.id);

  // this state will be initialized from context for the first time
  // will be updated by uppy on successful upload
  // updates the context and the UI when it is changed
  const [filesFromAnswer, setFilesFromAnswer] = useState(
    props.answer?.body?.files || []
  );
  useEffect(() => {
    if (
      filesFromAnswer === props.answer?.body?.files ||
      (props.answer?.body?.files === undefined && filesFromAnswer.length === 0)
    )
      return;
    handleAnswerChange(
      QuestionTypes.FILE,
      props.question.id,
      getAnswerBodyFilesChanged(filesFromAnswer),
      undefined,
      answerId
    );
  }, [filesFromAnswer]);

  // this state will be initialized from context for the first time
  // will be updated just by the input in this component
  // updates the context and the UI when it is changed
  const [answerText, setAnswerText] = useState(props.answer?.body?.text || '');
  useEffect(() => {
    if (
      answerText === props.answer?.body?.text ||
      (props.answer?.body?.text === undefined && answerText === '')
    )
      return;
    handleAnswerChange(QuestionTypes.TEXT, props.question.id, {
      text: answerText,
      files: filesFromAnswer,
    });
  }, [answerText]);

  // create a new qresponse if it does not exist
  useEffect(() => {
    if (!qResponse)
      createQResponse(selectedQuestionnaire.id).then(() =>
        loadQResponse(selectedQuestionnaire.id)
      );
  }, []);

  const uppyDashboardId = `uppy-dashboard-${props.question.id}`;
  const uppyXhrId = `uppy-xhr-${props.question.id}`;

  // set uppy xhr uploader
  useEffect(() => {
    if (qResponse)
      getAccessTokenSilently().then((token) => {
        const options = {
          endpoint: `${apiUrlPrefix}/forms/api/questions/${props.question.id}/files`,
          headers: { Authorization: `Bearer ${token}` },
          limit: 1,
          id: uppyXhrId,
        };
        uppy.getPlugin(uppyXhrId)
          ? uppy.getPlugin(uppyXhrId).setOptions(options)
          : uppy.use(XHR, options);
        uppy
          .getPlugin(uppyDashboardId)
          .setOptions({ singleFileFullScreen: false });
      });
  }, [getAccessTokenSilently, qResponse]);

  const handleAnswerTextChange = (event) => {
    const text = event.target.value;
    setAnswerText(text);
  };

  const classes = defaultStyles();

  // removes file from answer body in the answer context
  function removeFile(fileName) {
    let answerFiles = filesFromAnswer.filter((file) => file.name !== fileName);
    setFilesFromAnswer(answerFiles);
  }

  function getAnswerBodyFilesChanged(allFiles) {
    return {
      text: answerText,
      files: allFiles.map((file) => ({
        name: file.name,
        size: file.size,
      })),
    };
  }

  useEffect(() => {
    uppy
      .on('upload-success', (p, resp) => {
        setFilesFromAnswer((prevState) => [
          ...prevState,
          { name: p.name, size: p.size },
        ]);
        setAnswerId(resp.body.id);
      })
      .on('complete', (result) => {
        if (result.failed.length === 0) uppy.cancelAll();
      });

    return () => {
      uppy.off('upload-success').off('complete');
    };
  }, [filesFromAnswer, answerText]);

  function renderFileLink(file) {
    let linkContent = <>{file.name}</>;
    return (
      <AnswerFileLink
        answer={props.answer}
        file={file}
        linkContent={linkContent}
      ></AnswerFileLink>
    );
  }

  return (
    <div>
      <List className={classes.fileList} dense={true}>
        {filesFromAnswer.map((file) => {
          return (
            <React.Fragment key={file.name}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  secondary={(file.size / (1024 * 1024)).toFixed(2) + ' MB'}
                >
                  {renderFileLink(file)}
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => removeFile(file.name)}
                    size="large"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        })}
      </List>{' '}
      <StyledWrapper>
        <Dashboard
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          disabled={props.disabled}
          centerSingleFile={false}
          id={uppyDashboardId}
        />
      </StyledWrapper>
      <form>
        <TextField
          id="outlined-multiline-flexible"
          label="Additional Comments"
          multiline
          fullWidth
          maxRows={1000}
          value={answerText}
          margin="normal"
          variant="outlined"
          disabled={props.disabled}
          onChange={handleAnswerTextChange}
        />
      </form>
    </div>
  );
};

const StyledWrapper = styled.div`
  .uppy-Dashboard-inner {
    height: fit-content !important;
    min-height: 100px;
  }
  .uppy-Dashboard-AddFiles {
    min-height: 100px;
  }
  .uppy-Dashboard-AddFiles-list {
    display: none;
  }
`;

export default FileType;
