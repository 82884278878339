import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AnswerManagement from './forms/components/dataManagement/AnswerManagement';
import PollManagement from './forms/components/dataManagement/PollManagement';
import MessageManagement from './forms/components/dataManagement/MessageManagement';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { muiTheme } from './forms/layout/muiTheme';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Dashboard from './forms/components/dashboard/Dashboard';
import { SnackbarProvider } from 'notistack';
import VersionCheck from './components/VersionCheck';

const Loading = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Stack
        sx={{ textAlign: 'center', marginTop: '200px' }}
        data-testid="loading"
      >
        <Typography mb={1} variant="h1">
          App Loading
        </Typography>
        <Typography mb={1} variant="h3">
          Please wait until the app is loaded.
        </Typography>
        <Typography variant="body2">
          If you are not redirected within the 10 seconds, please reload the
          page.
        </Typography>
      </Stack>
    </ThemeProvider>
  );
};

export default function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const performLogin = async () => {
      await loginWithRedirect({});
    };
    performLogin();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <MessageManagement>
          <PollManagement>
            <AnswerManagement>
              <SnackbarProvider>
                <VersionCheck />
                <Dashboard />
              </SnackbarProvider>
            </AnswerManagement>
          </PollManagement>
        </MessageManagement>
      </ThemeProvider>
    </StyledEngineProvider>
  ) : (
    <Loading />
  );
}
