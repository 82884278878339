import React from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Explorer from '../explorer/Explorer';
import Messages from './Messages';

import { defaultStyles } from '../../layout/styleProvider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../../../config';

import { ReactComponent as LogoLight } from './../../../assets/logo-light.svg';
import { Stack } from '@mui/material';
import Footer from './Footer';
import { colorScheme } from '../../layout/globals';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
  const { logout } = useAuth0();

  const classes = defaultStyles();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen((state) => !state);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Stack
            direction={'row'}
            alignItems={'flex-end'}
            justifyContent={'center'}
            width={'100%'}
          >
            <LogoLight className={classes.headerLogo} />
            <Typography
              color="inherit"
              noWrap
              className={classes.title}
              style={{ marginTop: 3 }}
            >
              VDR
            </Typography>
          </Stack>

          <Messages />

          <Button
            variant="outlined"
            className={classes.button}
            color="inherit"
            sx={{ color: '#000' }}
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: getConfig('REACT_APP_AUTH0_LOGOUT_URL'),
                },
              })
            }
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        data-testid="side-navigation"
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        {open && (
          <IconButton
            sx={{
              position: 'absolute',
              right: open ? 0 : -50,
              bottom: 80,
              backgroundColor: '#fff',
              zIndex: 100000,
              border: '0.5px solid #ccc',
              borderRadius: '100% 0 0 100%',
              borderRight: 'none',
            }}
            onClick={toggleDrawer}
            size="small"
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        <Stack mt={12} pl={2}>
          <Typography variant="h6">Questionnaires</Typography>
        </Stack>

        <Explorer />
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          <Outlet />
          {!open && (
            <IconButton
              sx={{
                position: 'absolute',
                left: 56,
                bottom: 80,
                backgroundColor: colorScheme.blue20,
                zIndex: 100000,
                border: '0.5px solid #ccc',
                borderRadius: '0 100% 100% 0',
                borderLeft: 'none',
              }}
              onClick={toggleDrawer}
              size="small"
            >
              <ChevronRightIcon />
            </IconButton>
          )}
          <Footer />
        </Container>
      </main>
    </div>
  );
};

export default Dashboard;
